import logo from './logo.svg';
import './App.css';
import './css/beginnerCSS.css';
import './css/AboutCSS.css';
import './css/FeaturesCSS.css';
import './css/ResumeCSS.css';
import './css/PortfolioCSS.css';
import './css/ContentMeCSS.css';
import './css/FooterCSS.css';
import FullScreenHeader from './components/themes/fullScreen/Header';
import BodyBeginner from './components/themes/fullScreen/BodyBeginner';
import About from './components/themes/fullScreen/AboutMe';
import Features from './components/themes/fullScreen/Features'
import Resume from './components/themes/fullScreen/Resume'
import Portfolio from './components/themes/fullScreen/Portfolio'
import ProjectInfo from './components/themes/fullScreen/PortfolioFolder/ProjectInfo'
import ContentMe from './components/themes/fullScreen/ContentMe'
import Footer from './components/themes/fullScreen/Footer'
import FooterTwo from './components/themes/fullScreen/FooterTwo'
import { useState, useEffect } from 'react';
import { ProjectContext } from "./components/themes/fullScreen/PortfolioFolder/ProjectContext"
import { LanguageContext } from "./components/themes/fullScreen/Language"
import languageData from "./components/themes/fullScreen/JsonFolder/Language.json"
import { LanguageTextList } from "./components/themes/fullScreen/LanguageText"
import { useRef } from 'react';
function App() {
  const [projectItems, setProjectItems] = useState([])
  const [languageState, setLanguageState] = useState("ENG")
  const [languageList, setLanguageList] = useState(languageData)

  return (
    <div className="App">
      <ProjectContext.Provider value={{projectItems,setProjectItems}}>
        <LanguageContext.Provider value={{languageState, setLanguageState}}>
          <LanguageTextList.Provider value={{languageList, setLanguageList}}>
            <FullScreenHeader/>
            <BodyBeginner/>
            <About/>
            <Features/>
            <Resume/>
            <Portfolio/>
            <ContentMe/>
            <ProjectInfo/>
            <Footer/>
            <FooterTwo/>
          </LanguageTextList.Provider>
        </LanguageContext.Provider>
      </ProjectContext.Provider>
    </div>
  );
}

export default App;
