import React, {useContext} from 'react';
import { LanguageContext } from "./Language"
import { LanguageTextList } from "./LanguageText"

export default function Footer() {
    const {languageState, setLanguageState} = useContext(LanguageContext)
    const {languageList, setLanguageList}= useContext(LanguageTextList)
    const handleChangeView = (idName) => {
        const element = document.getElementById(idName);
        element?.scrollIntoView({
          behavior: 'smooth'
        })
      }
    return (
        <div className='FooterFullScreen'>
            <div className="FooterContainer">
                <div className="Copyright">
                    <div className="FootIcon">
                        <img src={process.env.PUBLIC_URL+'/sourse/image/ICON.jpg'} alt="" />
                        <h4>PORTFOLIO</h4>
                    </div>
                    <div className="shareGroupFoot">
                            <button><a href="https://wa.me/85295127145" target="_blank"><i class="bi bi-whatsapp"></i></a></button>
                            <button><i class="bi bi-facebook"></i></button>
                    </div>
                </div>
                <div className="QuickGroup">
                    <h4>{languageState != "ENG" ? "快速連結" : "QUICK LINK" }</h4>
                    <div className="QuickLink">
                        <a onClick={() =>{handleChangeView('beginner')}}>{languageState != "ENG" ? languageList.Header[0].Home[0].Cn : languageList.Header[0].Home[0].En }</a>
                        <a onClick={() =>{handleChangeView('aboutme')}}>{languageState != "ENG" ? languageList.Header[0].AboutMe[0].Cn : languageList.Header[0].AboutMe[0].En }</a>
                        <a onClick={() =>{handleChangeView('features')}}>{languageState != "ENG" ? languageList.Header[0].Features[0].Cn : languageList.Header[0].Features[0].En }</a>
                        <a onClick={() =>{handleChangeView('resume')}}>{languageState != "ENG" ? languageList.Header[0].Resume[0].Cn : languageList.Header[0].Resume[0].En }</a>
                        <a onClick={() =>{handleChangeView('portfolio')}}>{languageState != "ENG" ? languageList.Header[0].Protfolio[0].Cn : languageList.Header[0].Protfolio[0].En }</a>
                        <a onClick={() =>{handleChangeView('contactme')}}>{languageState != "ENG" ? languageList.Header[0].Contact[0].Cn : languageList.Header[0].Contact[0].En }</a>
                    </div>
                </div>               
            </div>
        </div>
    )
}
