import React, {useContext}  from 'react'
import { LanguageTextList } from "./LanguageText"
import { LanguageContext } from "./Language"

export default function Features() {
    const {languageList, setLanguageList}= useContext(LanguageTextList)
    const {languageState, setLanguageState} = useContext(LanguageContext)
    return (
        <div className='featuresGroup' id='features'>
            <div className="featuresContainer">
                <div className="feabody">
                    <p className='feaTitle'>{languageState != "ENG" ? languageList.Features[0].Title[0].Cn : languageList.Features[0].Title[0].En}</p>
                    <h1>{languageState != "ENG" ? languageList.Features[0].TitleTwo[0].Cn : languageList.Features[0].TitleTwo[0].En}</h1>
                    <div className="feaGroup">

                        <div className="groupBtn">
                            <div className="groupText">
                                <div className="feaIcon">
                                    <i class="bi bi-terminal"></i>
                                </div>
                                <p><a href="/">{languageState != "ENG" ? languageList.Features[0].Body[0].Cn : languageList.Features[0].Body[0].En}</a></p>
                                <p>{languageState != "ENG" ? languageList.Features[0].Body[1].Cn : languageList.Features[0].Body[1].En}</p>
                                <p>{languageState != "ENG" ? languageList.Features[0].Body[2].Cn : languageList.Features[0].Body[2].En}</p>
                                <div className="ArrowIcon">
                                    <i class="bi bi-arrow-return-right"></i>
                                </div>
                            </div>
                        </div>

                        <div className="groupBtn">
                            <div className="groupText">
                                <div className="feaIcon">
                                    <i class="bi bi-phone"></i>
                                </div>
                                <p><a href="/">{languageState != "ENG" ? languageList.Features[0].BodyTwo[0].Cn : languageList.Features[0].BodyTwo[0].En}</a></p>
                                <p>{languageState != "ENG" ? languageList.Features[0].BodyTwo[1].Cn : languageList.Features[0].BodyTwo[1].En}</p>
                                <p>{languageState != "ENG" ? languageList.Features[0].BodyTwo[2].Cn : languageList.Features[0].BodyTwo[2].En}</p>
                                <div className="ArrowIcon">
                                    <i class="bi bi-arrow-return-right"></i>
                                </div>
                            </div>
                        </div>

                        <div className="groupBtn">
                            <div className="groupText">
                                <div className="feaIcon">
                                    <i class="bi bi-laptop"></i>
                                </div>
                                <p><a href="/">{languageState != "ENG" ? languageList.Features[0].BodyThree[0].Cn : languageList.Features[0].BodyThree[0].En}</a></p>
                                <p>{languageState != "ENG" ? languageList.Features[0].BodyThree[1].Cn : languageList.Features[0].BodyThree[1].En}</p>
                                <p>{languageState != "ENG" ? languageList.Features[0].BodyThree[2].Cn : languageList.Features[0].BodyThree[2].En}</p>
                                <div className="ArrowIcon">
                                    <i class="bi bi-arrow-return-right"></i>
                                </div>
                            </div>
                        </div>

                        <div className="groupBtn">
                            <div className="groupText">
                                <div className="feaIcon">
                                    <i class="bi bi-bar-chart"></i>
                                </div>
                                <p><a href="/">{languageState != "ENG" ? languageList.Features[0].BodyFour[0].Cn : languageList.Features[0].BodyFour[0].En}</a></p>
                                <p>{languageState != "ENG" ? languageList.Features[0].BodyFour[1].Cn : languageList.Features[0].BodyFour[1].En}</p>
                                <p>{languageState != "ENG" ? languageList.Features[0].BodyFour[2].Cn : languageList.Features[0].BodyFour[2].En}</p>
                                <div className="ArrowIcon">
                                    <i class="bi bi-arrow-return-right"></i>
                                </div>
                            </div>
                        </div>

                        <div className="groupBtn">
                            <div className="groupText">
                                <div className="feaIcon">
                                    <i class="bi bi-hdd-rack"></i>
                                </div>
                                <p><a href="/">{languageState != "ENG" ? languageList.Features[0].BodyFive[0].Cn : languageList.Features[0].BodyFive[0].En}</a></p>
                                <p>{languageState != "ENG" ? languageList.Features[0].BodyFive[1].Cn : languageList.Features[0].BodyFive[1].En}</p>
                                <p>{languageState != "ENG" ? languageList.Features[0].BodyFive[2].Cn : languageList.Features[0].BodyFive[2].En}</p>
                                <div className="ArrowIcon">
                                    <i class="bi bi-arrow-return-right"></i>
                                </div>
                            </div>
                        </div>

                        <div className="groupBtn">
                            <div className="groupText">
                                <div className="feaIcon">
                                    <i class="bi bi-controller"></i>
                                </div>
                                <p><a href="/">{languageState != "ENG" ? languageList.Features[0].BodySix[0].Cn : languageList.Features[0].BodySix[0].En}</a></p>
                                <p>{languageState != "ENG" ? languageList.Features[0].BodySix[1].Cn : languageList.Features[0].BodySix[1].En}</p>
                                <p>{languageState != "ENG" ? languageList.Features[0].BodySix[2].Cn : languageList.Features[0].BodySix[2].En}</p>
                                <div className="ArrowIcon">
                                    <i class="bi bi-arrow-return-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
