import React, { useState , useRef, useEffect} from 'react'

import { useContext } from "react"
import { ProjectContext } from "./ProjectContext"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';


import { LanguageContext } from "../Language"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { type } from '@testing-library/user-event/dist/type';

export default function ProjectInfo(data) {

    const {projectItems, setProjectItems} = useContext(ProjectContext)
    let cartEmpty = projectItems.length == null ? true : false
    const [sourceDataList, setSourceDataList] = useState([])
    const dataList = useRef([])
    const images_array = cartEmpty && projectItems.multImage.split(',')
    let state = 0
    dataList.current = images_array
    useEffect(()=>{
        const handleData = async () =>{
            const sourceData = []
            if (dataList.current != null){

                for (let i = 0; i < dataList.current.length;i++){
                    let check = dataList.current[i].split('.')
                    if (check[1] != 'mp4'){
                        sourceData.push({
                            name : dataList.current[i],
                            type: "jpeg"
                        })
                    }
                    else{
                        sourceData.push({
                            name : dataList.current[i],
                            type: "mp4"
                        })
                    }
                }
                    //setSourceDataList(sourceData)
                setSourceDataList(sourceData)
                console.log(sourceData)
            }
        }
        handleData()
    },[projectItems.multImage])

    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
      progressCircle.current.style.setProperty('--progress', 1 - progress);
      progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        },
    };

    const {languageState, setLanguageState} = useContext(LanguageContext)

    return (
        <>
        {
            cartEmpty &&
                <div className='InfoFullScreen'>
                    <div className="InfoDataGroup">
                        <button className='InfoClose' onClick={() => {setProjectItems([])}}><i class="bi bi-x-circle"></i></button>
                        <h2>{projectItems.title}</h2>
                        <div className="Info">
                            <div className="InfoSwiper">
                            <Swiper
                                cssMode={true}
                                navigation={true}
                                pagination={true}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                                className="mySwiper"
                            >

                                        {sourceDataList && sourceDataList.map(d=>{
                                            return d.type != 'mp4' ?                                  
                                            <SwiperSlide key={d.name}>
                                                <img style={{objectFit : "contain"}} className='source' src={process.env.PUBLIC_URL+'/sourse/Project/'+d.name} alt="Portfolio Project Image" />
                                            </SwiperSlide>
                                            :
                                            <SwiperSlide key={d.name}>
                                                <video controls width="600" autoplay muted loop>
                                                    <source src={process.env.PUBLIC_URL+'/sourse/Project/'+d.name} type="video/mp4"></source>
                                                </video>
                                            </SwiperSlide>
                                        })}

                                </Swiper>
                            </div>
                            <div className="InfoData">
                                <div className="ProjectInfo">
                                    <h4>{languageState != "ENG" ? "作品介紹:" : "Project Info:" }</h4>
                                    <p>{languageState != "ENG" ? projectItems.infoCn : projectItems.infoEn }</p>
                                </div>
                                <div className="InfoText">
                                    <h4>{languageState != "ENG" ? "技術:" : "Technologies:" } <span>{projectItems.technologies}</span></h4>
                                    
                                </div>
                                <div className="InfoText">
                                    <h4>{languageState != "ENG" ? "類別:" : "Type:" } <span>{projectItems.type}</span></h4>
                                </div>
                                <div className="InfoText">
                                    <h4>{languageState != "ENG" ? "引擎:" : "Engine:" }  <span>{projectItems.engine}</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }
        </>
    )
}
