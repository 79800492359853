import React, {useContext}  from 'react'
import { LanguageTextList } from "./LanguageText"
import { LanguageContext } from "./Language"

export default function AboutMe() {
    const {languageList, setLanguageList}= useContext(LanguageTextList)
    const {languageState, setLanguageState} = useContext(LanguageContext)
    return (
        <div className='aboutMe' id='aboutme'>
            <div className="aboutMeContainer">
                <div className="titleAbout">
                    <p>{languageState != "ENG" ? languageList.AboutMe[0].Title[0].Cn : languageList.AboutMe[0].Title[0].En}</p>
                    <h2>{languageState != "ENG" ? languageList.AboutMe[0].TitleTwo[0].Cn : languageList.AboutMe[0].TitleTwo[0].En}</h2>
                </div>
                <div className="AboutInfo">
                    <div className="AboutBody">
                        <div className="AboutName">
                            <h2>{languageState != "ENG" ? languageList.AboutMe[0].BodyName[0].Cn : languageList.AboutMe[0].BodyName[0].En} <span>{languageState != "ENG" ? languageList.AboutMe[0].BodyNameTwo[0].Cn : languageList.AboutMe[0].BodyNameTwo[0].En}</span></h2>
                        </div>
                        <h3>{languageState != "ENG" ? languageList.AboutMe[0].Body[0].Cn : languageList.AboutMe[0].Body[0].En}</h3>
                    </div>
                    <div className="expYear">
                        <h1>3</h1>
                        <div className="expText">
                            <h3>{languageState != "ENG" ? languageList.AboutMe[0].Year[0].Cn : languageList.AboutMe[0].Year[0].En}</h3>
                        </div>                      
                    </div>
                </div>
                <div className="contactData">
                    <div className="smallScreenData">
                        <div className="conName smallGroup">
                            <span>{languageState != "ENG" ? languageList.AboutMe[0].Name[0].Cn : languageList.AboutMe[0].Name[0].En}</span>
                            <p>{languageState != "ENG" ? languageList.AboutMe[0].NameTwo[0].Cn : languageList.AboutMe[0].NameTwo[0].En}</p>
                        </div>
                        <div className="conEmail smallGroup">
                            <span>{languageState != "ENG" ? languageList.AboutMe[0].Email[0].Cn : languageList.AboutMe[0].Email[0].En}</span>
                            <p><a href= "mailto: masha181515@email.com">masha181515@gmail.com</a></p>       
                        </div>
                    </div>

                    <div className="smallScreenData">
                        <div className="conBirth smallGroup">
                            <span>{languageState != "ENG" ? languageList.AboutMe[0].Birth[0].Cn : languageList.AboutMe[0].Birth[0].En}</span>
                            <p>13-02-1999</p>
                        </div>
                        <div className="confrom smallGroup">
                            <span>{languageState != "ENG" ? languageList.AboutMe[0].Confrom[0].Cn : languageList.AboutMe[0].Confrom[0].En}</span>
                            <p>{languageState != "ENG" ? languageList.AboutMe[0].ConfromTwo[0].Cn : languageList.AboutMe[0].ConfromTwo[0].En}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
