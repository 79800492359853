import React from 'react'
import { ProjectContext } from "./ProjectContext"

import { useContext } from "react"
import ProjectInfo from "./ProjectInfo"

import { LanguageContext } from "../Language"

export default function Projectitems({data}) {
  const {projectItems, setProjectItems} = useContext(ProjectContext)

  const {languageState, setLanguageState} = useContext(LanguageContext)

  const handleShowData = (data) =>{
    setProjectItems(data)
    console.log(data)
  }

  return (
    <div className='Items'>
        <button onClick={() => handleShowData(data)}>
            <div className="itemImg">
              <img src={process.env.PUBLIC_URL+'/sourse/Project/'+data.image} alt="Portfolio Project Image"/>
            </div>

            <div className="ItemsData">
                <h2>{languageState != "ENG" ? data.titleCn : data.titleEn }</h2>
                <h4>{languageState != "ENG" ? data.infoCn : data.infoEn }</h4>
            </div>
        </button>
    </div>
  )
}

// import React, { useRef, useState } from 'react';
// // Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';

// // Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/effect-coverflow';
// import 'swiper/css/pagination';

// import 'swiper/swiper-bundle.css';

// // import required modules
// import { EffectCoverflow, Pagination } from 'swiper/modules';

// export default function Projectitems(data) {
//   return (
//     <>
//       <Swiper
//         effect={'coverflow'}
//         grabCursor={true}
//         centeredSlides={true}
//         slidesPerView={'auto'}
//         coverflowEffect={{
//           rotate: 50,
//           stretch: 0,
//           depth: 100,
//           modifier: 1,
//           slideShadows: true,
//         }}
//         pagination={true}
//         modules={[EffectCoverflow, Pagination]}
//         className="mySwiper"
//       >
//         <div className='swiperGroup'>
//             <SwiperSlide style={{width: "50%"}}>
//                 <div className='Items'>
//                     <button>
//                         <img src={process.env.PUBLIC_URL+'/sourse/image/Web/'+data.image} alt="Portfolio Project Image"/>
//                         <div className="ItemsData">
//                             <h2>{data.title}</h2>
//                             <h4>{data.info}</h4>
//                         </div>
//                     </button>
//                 </div>
//             </SwiperSlide>
//         </div>

//       </Swiper>
//     </>
//   );
// }
