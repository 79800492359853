import React, {useContext}  from 'react'
import { LanguageTextList } from "./LanguageText"
import { LanguageContext } from "./Language"
import { useState,useEffect } from "react"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ProjectItems from './PortfolioFolder/Projectitems'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import 'swiper/swiper-bundle.css';

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';

const URL = 'https://mashaps-sv.com/laravel/main/GetPortfolioData';

export default function Portfolio() {
  const {languageList, setLanguageList}= useContext(LanguageTextList)
  const {languageState, setLanguageState} = useContext(LanguageContext)

  let [productList, setProductList] = useState(null)
  let [projectType, setProjectType] = useState("null")
    //useEffect hook
    const [data,setData] = useState([])
    
  useEffect(()=>{
    fetch(URL, {
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*"
      },
    })
    .then(response => response.json())
    .then(data => {
      setData(data)
      setProjectType("ALL")
      // if (projectType != "ALL"){
      //   const filterItems = data.filter((d) => d.type === projectType)
      //   setProductList(filterItems)
      // }
      // else{
      //   setProductList(data)
      // }
    })
  },[])

  useEffect(() =>{
    if (data != null){
      if (projectType != "ALL"){
        const filterItems = data.filter((d) => d.type.split(',')[0] === projectType || d.type.split(',')[1] === projectType)
        setProductList(filterItems)
      }
      else{
        setProductList(data)
      }
    }
  },[projectType])

  const classState = (name) =>{
    if (name == projectType){
      return "selectPort"
    }
    else{
      return ""
    }
  }

  const handleChangeProject = (target) => {
    setProjectType(target)
  }

  let [itemSize, setItemSize] = useState("45%")

  useEffect(() => {
    if (window.innerWidth <= 768){
      setItemSize("100%")
    }
    else{
      setItemSize("35%")
    }
  },[window.innerWidth])

  return (
    <div className='PortFullScreen' id='portfolio'>
      <div className="PortContainer">
        <div className="PortTitle">
          <h4>{languageState != "ENG" ? languageList.Portfolio[0].Title[0].Cn : languageList.Portfolio[0].Title[0].En}</h4>
          <h2>{languageState != "ENG" ? languageList.Portfolio[0].TitleTwo[0].Cn : languageList.Portfolio[0].TitleTwo[0].En}</h2>
        </div>
        <div className="PorjectGroup">
          <div className="PortList">
            <div id='ALL' className={`PortName ${classState("ALL")}`}><button onClick={() => handleChangeProject("ALL")}>{languageState != "ENG" ? languageList.Portfolio[0].Type[0].Cn : languageList.Portfolio[0].Type[0].En}</button></div>
            <div id='Website' className={`PortName ${classState("Website")}`}><button onClick={() => handleChangeProject("Website")}>{languageState != "ENG" ? languageList.Portfolio[0].Type[1].Cn : languageList.Portfolio[0].Type[1].En}</button></div>
            <div id='App' className={`PortName ${classState("App")}`}><button onClick={() => handleChangeProject("App")}>{languageState != "ENG" ? languageList.Portfolio[0].Type[2].Cn : languageList.Portfolio[0].Type[2].En}</button></div>
            <div id='Game' className={`PortName ${classState("Game")}`}><button onClick={() => handleChangeProject("Game")}>{languageState != "ENG" ? languageList.Portfolio[0].Type[3].Cn : languageList.Portfolio[0].Type[3].En}</button></div>
            <div id='Event' className={`PortName ${classState("Event")}`}><button onClick={() => handleChangeProject("Event")}>{languageState != "ENG" ? languageList.Portfolio[0].Type[4].Cn : languageList.Portfolio[0].Type[4].En}</button></div>
          </div>
          <div className="PortData">
            <div className="SlickGroup">
            <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={'auto'}
              coverflowEffect={{
                rotate: 40,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              pagination={true}
              navigation={false}
              modules={[EffectCoverflow, Pagination]}
              className="mySwiper"
            >
                {
                  productList && productList.map((d)=>(
                    <SwiperSlide style={{width: itemSize}} key={d.id}>
                      <ProjectItems data={d}></ProjectItems>
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </div>
          </div>
        </div>
      </div>    
    </div>
  )
}
