import React, {useContext} from 'react'
import Typewriter from "typewriter-effect";
import { LanguageTextList } from "./LanguageText"
import { LanguageContext } from "./Language"

export default function BodyBeginner() {
    const {languageList, setLanguageList}= useContext(LanguageTextList)
    const {languageState, setLanguageState} = useContext(LanguageContext)
    const handleChangeView = (idName) => {
        const element = document.getElementById(idName);
        element?.scrollIntoView({
          behavior: 'smooth'
        })
      }
    return (
            <div className="fullscreen" id='beginner'>
                <div className="bg"></div>
                <div className="bg bg2"></div>
                <div className="bg bg3"></div>
                <div className="Conbody">
                    <div className="row">
                        <div className="textCentent">
                            <h1>{languageState != "ENG" ? languageList.Home[0].Frist[0].Cn : languageList.Home[0].Frist[0].En }</h1>
                            <h2>
                            <Typewriter
                                options={{
                                strings: [languageState != "ENG" ? languageList.Home[0].Designer[0].Cn : languageList.Home[0].Designer[0].En, 
                                languageState != "ENG" ? languageList.Home[0].Developer[0].Cn : languageList.Home[0].Developer[0].En,
                                languageState != "ENG" ? languageList.Home[0].Programmer[0].Cn : languageList.Home[0].Programmer[0].En],
                                autoStart: true,
                                loop: true,
                                }}
                            />
                            </h2>
                            <span className="comeForm">{languageState != "ENG" ? languageList.Home[0].Second[0].Cn : languageList.Home[0].Second[0].En}</span>
                            <div className="ViewWork">
                                <button onClick={() =>{handleChangeView('portfolio')}}>{languageState != "ENG" ? languageList.Home[0].Third[0].Cn : languageList.Home[0].Third[0].En}</button>
                                <div className="contactMeBTN textColor">
                                    <a onClick={() =>{handleChangeView('contactme')}}>{languageState != "ENG" ? languageList.Home[0].Contact[0].Cn : languageList.Home[0].Contact[0].En}</a>
                                    <i class="bi bi-caret-down"></i>
                                </div>
                            </div>
                        </div>
                        <div className="Icon">
                            <img src={process.env.PUBLIC_URL+'/sourse/image/ICON.jpg'} alt="MashaKar Icon" />
                        </div>
                    </div>
                </div>
                <div className="downArrow">
                    <i class="bi bi-arrow-down"></i>
                </div>
            </div>
    )
}
