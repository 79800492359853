import React from 'react'
import { useEffect, useState,useContext} from 'react';
import BodyBeginner from './BodyBeginner';
import { LanguageContext } from "./Language"
import { LanguageTextList } from "./LanguageText"

export default function FullScreenHeader() {
  
  let [navbar, setNavbar] = useState(false)
  let [languageNow, setLanguageNow] = useState("ENG")
  const {languageState, setLanguageState} = useContext(LanguageContext)
  const {languageList, setLanguageList}= useContext(LanguageTextList)

  const handleChangeView = (idName) => {
    const element = document.getElementById(idName);
    element?.scrollIntoView({
      behavior: 'smooth'
    })
  }

  const changeHeaderBackground = () =>{
    if (window.scrollY > 100){
      setNavbar(true)
    }
    else{
      setNavbar(false)
    }
  }

  const handleChangeText = (text) => {
    setLanguageState(text)
    setLanguageNow(text)
  }

  window.addEventListener('scroll', changeHeaderBackground)

  return (
    <header>
      <div>
        <div className={
          navbar ? 'infoTitle headerBackgroundScroll' : 'infoTitle headerBackgroundBeginner'
        }>
          <div className="container">
            <div className="imgFrame">
              <div className="imgBox">           
                <img src={process.env.PUBLIC_URL+'/logo192.png'} alt="MashakarIcon" />
              </div>
              <p>PORTFOLIO</p>
            </div>

            <div className="detailInfo textColor">
              <a onClick={() =>{handleChangeView('beginner')}}>{languageState != "ENG" ? languageList.Header[0].Home[0].Cn : languageList.Header[0].Home[0].En }</a>
              <a onClick={() =>{handleChangeView('aboutme')}}>{languageState != "ENG" ? languageList.Header[0].AboutMe[0].Cn : languageList.Header[0].AboutMe[0].En }</a>
              <a onClick={() =>{handleChangeView('features')}}>{languageState != "ENG" ? languageList.Header[0].Features[0].Cn : languageList.Header[0].Features[0].En }</a>
              <a onClick={() =>{handleChangeView('resume')}}>{languageState != "ENG" ? languageList.Header[0].Resume[0].Cn : languageList.Header[0].Resume[0].En }</a>
              <a onClick={() =>{handleChangeView('portfolio')}}>{languageState != "ENG" ? languageList.Header[0].Protfolio[0].Cn : languageList.Header[0].Protfolio[0].En }</a>
              <a onClick={() =>{handleChangeView('contactme')}}>{languageState != "ENG" ? languageList.Header[0].Contact[0].Cn : languageList.Header[0].Contact[0].En }</a>
              <div class="dropdown">
                <button class="dropbtn">{languageNow}</button>
                <div class="dropdown-content">
                  <a onClick={() =>{handleChangeText('ENG')}}>ENG</a>
                  <a onClick={() =>{handleChangeText('中')}}>中</a>
                </div>
              </div>
            </div>

            <div className="detailMenu">
              <button class="detailMenubtn"><i class="bi bi-list"></i></button>
                <div class="detailMenudropdown-content">
                <a onClick={() =>{handleChangeView('beginner')}}>{languageState != "ENG" ? languageList.Header[0].Home[0].Cn : languageList.Header[0].Home[0].En }</a>
              <a onClick={() =>{handleChangeView('aboutme')}}>{languageState != "ENG" ? languageList.Header[0].AboutMe[0].Cn : languageList.Header[0].AboutMe[0].En }</a>
              <a onClick={() =>{handleChangeView('features')}}>{languageState != "ENG" ? languageList.Header[0].Features[0].Cn : languageList.Header[0].Features[0].En }</a>
              <a onClick={() =>{handleChangeView('resume')}}>{languageState != "ENG" ? languageList.Header[0].Resume[0].Cn : languageList.Header[0].Resume[0].En }</a>
              <a onClick={() =>{handleChangeView('portfolio')}}>{languageState != "ENG" ? languageList.Header[0].Protfolio[0].Cn : languageList.Header[0].Protfolio[0].En }</a>
              <a onClick={() =>{handleChangeView('contactme')}}>{languageState != "ENG" ? languageList.Header[0].Contact[0].Cn : languageList.Header[0].Contact[0].En }</a>
                  <div className="lan">
                    <a onClick={() =>{handleChangeText('ENG')}}>ENG</a>
                    <a onClick={() =>{handleChangeText('中')}}>中</a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
