import ProgressBar from "@ramonak/react-progress-bar";
import React, {useContext}  from 'react'
import { LanguageTextList } from "./LanguageText"
import { LanguageContext } from "./Language"

export default function Resume() {

    const {languageList, setLanguageList}= useContext(LanguageTextList)
    const {languageState, setLanguageState} = useContext(LanguageContext)
    return (
        <div className='ResFullScreen' id='resume'>
            <div className="ResContainer">
                <div className="ResumeTitleName">
                    <div className="ResumeTitle">
                        <p>{languageState != "ENG" ? languageList.Resume[0].Title[0].Cn : languageList.Resume[0].Title[0].En}</p>
                    </div>
                    <h2>{languageState != "ENG" ? languageList.Resume[0].TitleTwo[0].Cn : languageList.Resume[0].TitleTwo[0].En}</h2>
                </div>

                <div className="MyEdEx">
                    <div className="EdExGroup">
                        <h3>{languageState != "ENG" ? languageList.Resume[0].Education[0].Cn : languageList.Resume[0].Education[0].En}</h3>
                        <div className="EdExData">
                                <div className="EdExInfo">
                                    <h4>{languageState != "ENG" ? languageList.Resume[0].Body[0].Cn : languageList.Resume[0].Body[0].En}</h4>
                                    <p>{languageState != "ENG" ? languageList.Resume[0].Body[1].Cn : languageList.Resume[0].Body[1].En}</p>
                                    <span>{languageState != "ENG" ? languageList.Resume[0].Body[2].Cn : languageList.Resume[0].Body[2].En}</span>
                                </div>
                                <div className="EdExInfo">
                                    <h4>{languageState != "ENG" ? languageList.Resume[0].BodyTwo[0].Cn : languageList.Resume[0].BodyTwo[0].En}</h4>
                                    <p>{languageState != "ENG" ? languageList.Resume[0].BodyTwo[1].Cn : languageList.Resume[0].BodyTwo[1].En}</p>
                                    <span>{languageState != "ENG" ? languageList.Resume[0].BodyTwo[2].Cn : languageList.Resume[0].BodyTwo[2].En}</span>
                                </div>
                            </div>
                    </div>
                    <div className="EdExGroup">
                        <h3>{languageState != "ENG" ? languageList.Resume[0].Experience[0].Cn : languageList.Resume[0].Experience[0].En}</h3>
                        <div className="EdExData">
                            <div className="EdExInfo">
                                <h4>{languageState != "ENG" ? languageList.Resume[0].BodyThree[0].Cn : languageList.Resume[0].BodyThree[0].En}</h4>
                                <p>{languageState != "ENG" ? languageList.Resume[0].BodyThree[1].Cn : languageList.Resume[0].BodyThree[1].En}</p>
                                <span>{languageState != "ENG" ? languageList.Resume[0].BodyThree[2].Cn : languageList.Resume[0].BodyThree[2].En}</span>
                            </div>
                            <div className="EdExInfo">
                                <h4>{languageState != "ENG" ? languageList.Resume[0].BodyFour[0].Cn : languageList.Resume[0].BodyFour[0].En}</h4>
                                <p>{languageState != "ENG" ? languageList.Resume[0].BodyFour[1].Cn : languageList.Resume[0].BodyFour[1].En}</p>
                                <span>{languageState != "ENG" ? languageList.Resume[0].BodyFour[2].Cn : languageList.Resume[0].BodyFour[2].En}</span>
                            </div>
                            <div className="EdExInfo">
                                <h4>{languageState != "ENG" ? languageList.Resume[0].BodyFive[0].Cn : languageList.Resume[0].BodyFive[0].En}</h4>
                                <p>{languageState != "ENG" ? languageList.Resume[0].BodyFive[1].Cn : languageList.Resume[0].BodyFive[1].En}</p>
                                <span>{languageState != "ENG" ? languageList.Resume[0].BodyFive[2].Cn : languageList.Resume[0].BodyFive[2].En}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="MySkillsCon">
                    <h2>{languageState != "ENG" ? languageList.Resume[0].Skill[0].Cn : languageList.Resume[0].Skill[0].En}</h2>
                    <div className="MySkillGroup">
                        <div className="MySkillList">
                            <div className="MySkillData">
                                <div className="SkillName">
                                    <h3>Web Design</h3>
                                    <div className="SkillProgress"><ProgressBar completed={60}   bgColor="rgb(86, 173, 255)" height="15px"/></div>
                                </div>
                            </div>
                            <div className="MySkillData">
                                <div className="SkillName">
                                    <h3>JavaScript</h3>
                                    <div className="SkillProgress"><ProgressBar completed={50}   bgColor="rgb(86, 173, 255)" height="15px"/></div>
                                </div>
                            </div>
                            <div className="MySkillData">
                                <div className="SkillName">
                                    <h3>HTML/CSS</h3>
                                    <div className="SkillProgress"><ProgressBar completed={70}   bgColor="rgb(86, 173, 255)" height="15px"/></div>
                                </div>
                            </div>
                            <div className="MySkillData">
                                <div className="SkillName">
                                    <h3>Python</h3>
                                    <div className="SkillProgress"><ProgressBar completed={40}   bgColor="rgb(86, 173, 255)" height="15px"/></div>
                                </div>
                            </div>
                        </div>
                        <div className="MySkillList">
                            <div className="MySkillData">
                                <div className="SkillName">
                                    <h3>React JS</h3>
                                    <div className="SkillProgress"><ProgressBar completed={65}   bgColor="rgb(86, 173, 255)" height="15px"/></div>
                                </div>
                            </div>
                            <div className="MySkillData">
                                <div className="SkillName">
                                    <h3>React Native</h3>
                                    <div className="SkillProgress"><ProgressBar completed={40}   bgColor="rgb(86, 173, 255)" height="15px"/></div>
                                </div>
                            </div>
                            <div className="MySkillData">
                                <div className="SkillName">
                                    <h3>Laravel PHP</h3>
                                    <div className="SkillProgress"><ProgressBar completed={80}   bgColor="rgb(86, 173, 255)" height="15px"/></div>
                                </div>
                            </div>
                            <div className="MySkillData">
                                <div className="SkillName">
                                    <h3>Bootstrap</h3>
                                    <div className="SkillProgress"><ProgressBar completed={50}   bgColor="rgb(86, 173, 255)" height="15px"/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
