import React,{useEffect, useState,useRef} from 'react'
import Validation from './MessageFolder/Validation'
import emailjs from 'emailjs-com';
import {useContext}  from 'react'
import { LanguageTextList } from "./LanguageText"
import { LanguageContext } from "./Language"

export default function ContentMe() {
    const {languageList, setLanguageList}= useContext(LanguageTextList)
    const {languageState, setLanguageState} = useContext(LanguageContext)
    const form = useRef()
    const [inputData, setInputData] = useState({
        name: '',
        phone: '',
        email: '',
        subject: '',
        message:''
    })

    const errors = {};

    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,6}$/;
    const password_pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,}$/;

    if (inputData.name === ""){
        errors.name = "Name is Required!";
    }

    if (inputData.phone === ""){
        errors.phone = "Phone is Required!";
    }

    if (inputData.email === ""){
        errors.email = "Email is Required";
    }else if (!email_pattern.test(inputData.email)){
        errors.email = "Email did'nt match";
    }

    if (inputData.subject ===""){
        errors.subject = "Subject is Required!";
    }

    if (inputData.message ===""){
        errors.message = "Message is Required!";
    }
    const [correctState, setCorrectState] = useState(false)
    
    const [error, setError] = useState({})

    function checkContactMeData (event){
        const newObj = {...inputData, [event.target.name]: event.target.value}
        setInputData(newObj)
    }

    const submitChectFunction = (event) =>{
        event.preventDefault();
        setError(errors)

        if (!errors.name && !errors.phone && !errors.email  && !errors.subject && !errors.message){
            emailjs.sendForm('service_ghr1p6v', 'template_bcem73y', form.current, 'trB1p6N-tH_jyh1mJ')
            .then((result) => {
                setCorrectState(true)
                console.log("work")  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
            
        }
        else if (errors.name || errors.phone || errors.email || errors.subject || errors.message){
            setCorrectState(false)
            console.log("no work")
        }
    }

    return (
        <div className='ContentMeFullScreen' id='contactme'>
            <div className="contentMeCon">
                <div className="contentTitle">
                    <p>{languageState != "ENG" ? languageList.Contact[0].Title[0].Cn : languageList.Contact[0].Title[0].En}</p>
                    <h2>{languageState != "ENG" ? languageList.Contact[0].TitleTwo[0].Cn : languageList.Contact[0].TitleTwo[0].En}</h2>
                </div>
                <div className="contentMeGroup">

                    <div className="meInfoGroup">
                        <img src={process.env.PUBLIC_URL+'/sourse/image/contact1.png'} alt="" />
                        <h2>{languageState != "ENG" ? languageList.Contact[0].Name[0].Cn : languageList.Contact[0].Name[0].En}</h2>
                        <p>{languageState != "ENG" ? languageList.Contact[0].Introduce[0].Cn : languageList.Contact[0].Introduce[0].En}</p>
                        <h6>{languageState != "ENG" ? languageList.Contact[0].Phone[0].Cn : languageList.Contact[0].Phone[0].En}: <span>+852 95127145</span></h6>
                        <h6>{languageState != "ENG" ? languageList.Contact[0].Email[0].Cn : languageList.Contact[0].Email[0].En}: <span>masha181515@gmail.com</span></h6>
                        <p>{languageState != "ENG" ? languageList.Contact[0].Last[0].Cn : languageList.Contact[0].Last[0].En}</p>

                        <div className="shareGroup">
                            <button><a href="https://wa.me/85295127145" target="_blank"><i class="bi bi-whatsapp"></i></a></button>
                            <button><i class="bi bi-facebook"></i></button>
                        </div>

                    </div>

                    <div className="meInputGroup">
                        <form ref={form} onSubmit={submitChectFunction}>
                            <div className="firstDataGroup">
                                <div className="ContentMeData">
                                    <p>{languageState != "ENG" ? languageList.Contact[0].Body[0].Cn : languageList.Contact[0].Body[0].En}</p>
                                    <input name='name' type="text" onChange={checkContactMeData}/>
                                    {error.name && <p style={{color: "red", margin:"0px"}}>{error.name}</p>}
                                </div>
                                <div className="ContentMeData">
                                    <p>{languageState != "ENG" ? languageList.Contact[0].Body[1].Cn : languageList.Contact[0].Body[1].En}</p>
                                    <input name='phone' type="text" onChange={checkContactMeData}/>
                                    {error.phone && <p style={{color: "red", margin:"0px"}}>{error.phone}</p>}
                                </div>
                            </div>
                            <div className="ContentMeData">
                                <p>{languageState != "ENG" ? languageList.Contact[0].Body[2].Cn : languageList.Contact[0].Body[2].En}</p>
                                <input name='email' type="email" onChange={checkContactMeData}/>
                                {error.email && <p style={{color: "red", margin:"0px"}}>{error.email}</p>}
                            </div>
                            <div className="ContentMeData">
                                <p>{languageState != "ENG" ? languageList.Contact[0].Body[3].Cn : languageList.Contact[0].Body[3].En}</p>
                                <input name='subject' type="text" onChange={checkContactMeData}/>
                                {error.subject && <p style={{color: "red", margin:"0px"}}>{error.subject}</p>}
                            </div>
                            <div className="ContentMeDataMessage">
                                <p>{languageState != "ENG" ? languageList.Contact[0].Body[4].Cn : languageList.Contact[0].Body[4].En}</p>
                                <textarea name='message' cols="40" type="text" onChange={checkContactMeData}/>
                                {error.message && <p style={{color: "red", margin:"0px"}}>{error.message}</p>}
                            </div>
                            <div className="MessageConfirm">
                                <button>{languageState != "ENG" ? languageList.Contact[0].Body[5].Cn : languageList.Contact[0].Body[5].En}</button>             
                            </div>
                            {
                                correctState && 
                                <div className="MessageCorrent">
                                    <i class="bi bi-check-circle-fill"></i><span>{languageState != "ENG" ? languageList.Contact[0].Body[6].Cn : languageList.Contact[0].Body[6].En}</span>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
