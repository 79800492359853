import React from 'react'

export default function FooterTwo() {
    return (
        <div className='FooterFullScreen'>
            <div className="FooterTwoContainer">
                <p>© 2024. All rights reserved by Alan Kwok</p>
            </div>
        </div>
    )
}
